import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Template from "@/views/Template";
import ProductPresentation from "@/components/ProductPresentation";
import ProductAdvantages from "@/components/ProductAdvantages";
import AboutProduct from "@/components/AboutProduct";
import Typography from "@/components/Typography";
import InvestTime from "@/containers/InvestTime";
import PlansBox from "@/components/PlansBox";
export const _frontmatter = {
  "title": "MEI",
  "path": "/mei",
  "date": "2019-01-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Template;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProductPresentation advantages={["Abertura de CNPJ GRATUITA", "Relátorio Mensal de Receitas", "Consutorio para abertura de conta corrente MEI", "Elaboração de envio da DASN-SIMEI", "Elaboração DECORE", "Consultoria para modelagem de negócios", "Elaboração completa de folha de pagamento", "Alteração de Dados (Endereço, atividades, etc.)."]} sideTitle="Plano MEI" contactLink="/https://wa.me/5581994019577" knowMoreLink="/https://www.instagram.com/leancontabilidade" description="São mais de 450 atividades permitidas por lei entre comércio, indústria e serviços. Nós analisamos detalhadamente para enquadrar no tipo de atividade adequada." title={() => <>
      O{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        MEI
      </Typography>{" "}
      (Micro Empreendedor Individual) é a porta de entrada para o mundo dos
      empreendedores.
    </>} mdxType="ProductPresentation" />{" "}
    <AboutProduct stepOne={() => <>
      Abrimos o seu{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        MEI
      </Typography>{" "}
      no mesmo dia e de maneira
    </>} stepTwo={() => <>
      A partir daí, fazemos todo o{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        acompanhamento mensal
      </Typography>
      , que inclui:
    </>} name="MEI" items={[[], ["Preenchimento do relatório Mensal da empresa;", "Escrituração do Livro-Caixa para redução de tributos;", "Fornecimento de informações para abertura da conta corrente MEI;", "Geração do DAS mensal para pagamento;", "Elaboração e envio da DASN-SIMEI;", "Elaboração de DECORE para comprovação de rendimento do títular do MEI", "Elaboração completa de Folha de Pagamento para MEI (até 1 funcionário) com envio de folha, holerite e geração de encargos sociais"], []]} hasLabel="Quero abrir um MEI" openLabel="Já tenho um MEI" openLink="https://wa.me/5581994019577?text=Olá, estou precisando abrir um MEI e gostaria de mais informações." hasLink="https://wa.me/5581994019577?text=Olá, já tenho um MEI. Estou precisando de ajuda e gostaria de mais informações." knowMoreLink="https://www.instagram.com/leancontabilidade" mdxType="AboutProduct" />
    <InvestTime mdxType="InvestTime">
  <Typography align="center" color="primary" variant="h1" mdxType="Typography">
    Invista mais tempo no seu negócio{" "}
    <Typography as="span" color="primary" variant="h1" weight={300} mdxType="Typography">
      e
    </Typography>{" "}
    deixe a burocracia com a gente.{" "}
    <Typography as="span" color="primary" variant="h1" weight={300} mdxType="Typography">
      Faça já sua
    </Typography>{" "}
    contabilidade online.
  </Typography>
    </InvestTime>
    <ProductAdvantages description="Praticamente todo mundo pode ser MEI, desde que não tenha vínculo
              societário ou seja administrador de outras empresas, fature até R$
              81mil/ano e tenha até 01 funcionário, não seja servidor público ou
              pensionista federal." items={["Ter CNPJ e Alvará;", "Emitir NF", "Acesso a serviços bancários", "Possibilidade de vender para o Governo e para empresas", "Menor custo tributário", "Aposentadoria por idade ou invalidez", "Auxílio Doença", "Salário Maternidade", "Pensão por morte (para a família)"]} title={() => <>
      Confira os principais{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        Benefícios
      </Typography>{" "}
      de ser{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        MEI
      </Typography>
    </>} mdxType="ProductAdvantages" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      